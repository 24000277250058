<template>
    <div class="hello">
        <span class="fs-2 fw-bold">Hello, {{ $store.getters.GET_USER_NAME }}!</span>
        <div class="d-flex flex-column gap-2 w-100 h-100">
            <div class="d-flex flex-lg-row flex-column gap-3">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-4 mb-5 mb-lg-0 py-2" v-for="stat in visitor_stats" :key="stat">
                        <div class="card shadow bg-white d-flex align-items-stretch flex-column px-2 py-3 h-100"
                            style="border-radius: 20px;"
                            data-aos="fade-up" data-aos-delay="100">
                            <div class="p-2 h-100">
                                <h4 class="card-title text-center">Tat Sing {{ stat.company_name }}</h4>
                            </div>
                            <div class="card-body mx-auto d-flex flex-column text-center">
                                <span style="font-size: small;">No. of Visitors</span>
                                <span class="fw-bold text-theme" style="font-size: xx-large;">{{ stat.all_time }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="shadow-sm pt-4 w-100" style="border-radius: 0.75rem;" v-if="$store.getters['get_perm']('viewLogistics')">
                <div class="px-4 d-flex justify-content-between flex-wrap mb-2">
                    <h4 class="fw-bold">Shipment Transactions</h4>
                    <div class="d-flex flex-wrap justify-content-end gap-4 w-100">
                        <router-link to="/update-status" class="text-decoration-none">
                            <button type="button" class="btn text-white bg-theme px-4" >
                                <i class="bi bi-plus"></i> Add New
                            </button>
                        </router-link>
                    </div>
                    <div class="d-flex justify-content-between w-100 pt-3">
                        <div>
                            <span>Show</span>
                            <select v-model="perPage" name="perPage" id="perPage" class="p-1 mx-2 rounded border" style="width: 75px;">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                            <span>Entries</span>
                        </div>
                        <div>
                            <input v-model="search" type="text" name="search" class="w-100 border rounded py-1 px-2" placeholder="Search">
                        </div>
                    </div>
                </div>
                <div id="logistic_table" class="overflow-auto p-3 position-relative">
                    <h5 class="w-100 text-center fw-bold" :class="{'text-success': deleteMsg.toLowerCase().includes('success'),
                    'text-danger': !deleteMsg.toLowerCase().includes('success') }" v-show="deleteMsg">
                        Logistic - {{ deleteMsg }}
                    </h5>
                    <Preloader class="position-absolute w-100 h-100" v-show="isLoading && !$store.state.isLoading" />
                    <table class="table table-hover w-100">
                        <thead class="border-bottom border-1 text-light">
                            <tr class="w-100">
                                <th scope="col" class="py-1" style="width: 25px;"></th>
                                <th colspan="2" scope="col" class="py-1 bg-secondary-theme text-center"></th>
                                <th colspan="2" scope="col" class="py-1 bg-secondary-theme text-center">Status</th>
                                <th scope="col" class="py-1" style="width: 25px;"></th>
                            </tr>
                            <tr style="font-size: small;">
                                <th scope="col" class="py-2" style="width: 25px;"></th>
                                <th scope="col" class="py-2 bg-secondary-theme text-center position-relative" style="width: 20%;" @click="sortBy('srn')">SRN <div class="d-flex flex-column position-absolute" style="top: 10%; right: 5px;"><i class="bi bi-chevron-up" :class="{'': sort_direction === 'asc', 'opacity-50': sort_direction != 'asc'}" style="height: 10px;"></i><i class="bi bi-chevron-down" :class="{'': sort_direction === 'desc', 'opacity-50': sort_direction != 'desc'}"></i></div></th>
                                <th scope="col" class="py-2 bg-secondary-theme text-center" style="width: 30%;">Description</th>
                                <th scope="col" class="py-2 bg-secondary-theme text-center w-25">Delivery Date</th>
                                <th scope="col" class="py-2 bg-secondary-theme text-center w-25">Date and Time</th>
                                <th scope="col" class="py-2" style="width: 25px;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="font-size: small;" v-for="(data, index) in $store.state.logistic.logistic_data.data" :key="data">
                                <td scope="row"></td>
                                <td class="fw-bold text-theme">{{ data.srn }} ({{data.isFinished}})</td>
                                <td class="overflow-hidden desc-table-con" stye="width: 258.6px;">
                                    <div class="desc-table-tr">{{ data.last_edited >= 0 
                                                                    ? (data.inner_data[Object.keys(data.inner_data)[data.last_edited]][Object.keys(data.inner_data[Object.keys(data.inner_data)[data.last_edited]])[0]]
                                                                        ? data.inner_data[Object.keys(data.inner_data)[data.last_edited]][Object.keys(data.inner_data[Object.keys(data.inner_data)[data.last_edited]])[0]]
                                                                        : 'No Data') 
                                                                    : 'No Data' }}</div>
                                    <table name="desc-table" class="d-none table table-hover w-100 p-0 m-0">
                                        <tbody class="h-100 w-100 p-0 m-0">
                                            <tr class="desc-table-tr" v-for="description in $store.state.logistic.logistic_data.data[index].inner_data" :key="description">
                                                <td>{{ description[Object.keys(description)[0]] ? description[Object.keys(description)[0]] : 'No Data' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td class="overflow-hidden delivery-table-con" stye="width: 258.6px;">
                                    <div class="delivery-table-tr" name="delivery-dates">{{ $store.state.logistic.static_table_data[data.last_edited]}}</div>
                                    <table name="delivery-table" class="d-none table table-hover w-100 p-0 m-0 h-100">
                                        <tbody>
                                            <tr class="delivery-table-tr" v-for="data in $store.state.logistic.static_table_data" :key="data">
                                                <td>{{data}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td class="overflow-hidden time-table-con" stye="width: 258.6px;">
                                    <div class="time-table-tr">
                                        {{ data.last_edited >= 0 
                                            ? (data.inner_data[Object.keys(data.inner_data)[data.last_edited]][Object.keys(data.inner_data[Object.keys(data.inner_data)[data.last_edited]])[1]]
                                                || data.inner_data[Object.keys(data.inner_data)[data.last_edited]][Object.keys(data.inner_data[Object.keys(data.inner_data)[data.last_edited]])[2]]
                                                ? data.inner_data[Object.keys(data.inner_data)[data.last_edited]][Object.keys(data.inner_data[Object.keys(data.inner_data)[data.last_edited]])[1]] + ' | ' + data.inner_data[Object.keys(data.inner_data)[data.last_edited]][Object.keys(data.inner_data[Object.keys(data.inner_data)[data.last_edited]])[2]]
                                                : 'No Data') 
                                            : 'No Data' }}
                                    </div>
                                    <table name="time-table" class="d-none table table-hover w-100 p-0 m-0 h-100">
                                        <tbody>
                                            <tr class="time-table-tr" v-for="date_time in $store.state.logistic.logistic_data.data[index].inner_data" :key="date_time">
                                                <td>{{ date_time[Object.keys(date_time)[1]] || date_time[Object.keys(date_time)[2]] ? date_time[Object.keys(date_time)[1]] + ' | ' + date_time[Object.keys(date_time)[2]]: 'No Data' }}</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <button name="show-info" @click="show_extra_info(index)" type="button" class="bg-transparent border-0"><i name="show" class="bi bi-chevron-down text-theme"></i><i name="unshow" class="d-none bi bi-chevron-up text-theme"></i></button>
                                        <button type="button" class="bg-transparent border-0" @click="edit(data.srn)" v-if="$store.getters['get_perm']('updateLogistic')"><i class="bi bi-pencil-square text-secondary-theme"></i></button>
                                        <button type="button" class="bg-transparent border-0" @click="deleteModal(data.id)" v-if="$store.getters['get_perm']('deleteLogistic')"><i class="bi bi-trash text-theme"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" :maxVisibleButtons="4" @pagechanged="onPageChange" />
                </div>
            </div>
        </div>
        <Modal v-if="$store.getters['get_perm']('deleteLogistic')">
            <template v-slot:head>
                <h5 class="modal-title">Delete Logistic</h5>
                <button type="button" class="close" @click="closeModal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </template>
            <template v-slot:main>
                <div>Do you want to delete <strong>{{ to_deleteLogistic.srn }}</strong>?</div>
                <div>The Corresponding SRN has data? <strong>{{ to_deleteLogistic.last_edited > -1 ? 'Yes' : 'No' }}</strong></div>
            </template>
            <template v-slot:footer>
                <button type="button" class="bg-secondary-theme text-white p-1 rounded" style="width:75px;" @click="closeModal">Close</button>
                <button type="button" class="bg-theme text-white p-1 rounded" style="width:75px;" @click="del(to_deleteLogistic.id)">Delete</button>
            </template>
        </Modal>
    </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
import Pagination from "../../../components/Pagination.vue";
import Preloader from "../../../components/Preloader.vue";
import Modal from "../../../components/Modal.vue";
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('tablecontroller', 'logistic')

export default {
  name: 'Dashboard',
  components: {
    Pagination,
    Preloader,
    Modal
  },
  data() {
    return {
        table_option: {
            search: this.search,
            sort_by: this.sort_by,
            sort_dir: this.sort_dir,
            perPage: this.perPage,
            currentPage: 1
        },
        show_infoButton: [],
        time_tables: [],
        desc_tables: [],
        delivery_tables: [],
        desc_table_con: [],
        delivery_tables_con: [],
        time_table_con: [],
        lastTime: [],
        lastDesc: [],
        showArray: [],
        unshowArray: [],
        to_deleteLogistic: {},
        deleteMsg: '',
        visitor_stats: {}
    }
  },
  mounted() {
    this.$store.dispatch('getVisitorStats').then(() => {
        this.visitor_stats = this.$store.state.visitor_stats
        }
    )
    
    if(this.$store.getters['get_perm']('viewLogistics')) {
        this.$store.dispatch('logistic/getLogistics', this.table_option);

        this.show_infoButton = document.getElementsByName('show-info');
        this.time_tables = document.getElementsByName('time-table');
        this.desc_tables = document.getElementsByName('desc-table');
        this.delivery_tables = document.getElementsByName('delivery-table');

        this.desc_table_con = document.getElementsByClassName('desc-table-con');
        this.delivery_table_con = document.getElementsByClassName('delivery-table-con');
        this.time_table_con = document.getElementsByClassName('time-table-con');

        this.lastTime= document.getElementsByName('last-edited-time');
        this.lastDesc= document.getElementsByName('last-edited-desc');
        this.showArray= document.getElementsByName('show');
        this.unshowArray= document.getElementsByName('unshow');
    }
  },
  methods: {
    onPageChange(page) {
      this.table_option.currentPage = page;
      this.$store.dispatch('logistic/getLogistics', this.table_option);
    },
    show_extra_info(i) {
        this.desc_tables[i].classList.toggle('d-none');
        this.time_tables[i].classList.toggle('d-none');
        this.delivery_tables[i].classList.toggle('d-none');

        this.desc_table_con[i].classList.toggle('opened');
        this.delivery_table_con[i].classList.toggle('opened');
        this.time_table_con[i].classList.toggle('opened');

        this.showArray[i].classList.toggle('d-none');
        this.unshowArray[i].classList.toggle('d-none');

        if((this.desc_table_con[i].classList.contains('opened') 
            && this.time_table_con[i].classList.contains('opened') 
            && this.delivery_table_con[i].classList.contains('opened'))) {
                
            var descTable_tr = this.desc_table_con[i].getElementsByClassName('desc-table-tr');
            var deliveryTable_tr = this.delivery_table_con[i].getElementsByClassName('delivery-table-tr');
            var timeTable_tr = this.time_table_con[i].getElementsByClassName('time-table-tr');

            var height_array = [[], [], []];


            for(var j = 0; j < deliveryTable_tr.length; j++) {
                var delivery_tr = deliveryTable_tr[j].getBoundingClientRect();
                var desc_tr = descTable_tr[j].getBoundingClientRect();
                var time_tr = timeTable_tr[j].getBoundingClientRect();

                height_array[0].push(delivery_tr.bottom - delivery_tr.top);
                height_array[1].push(desc_tr.bottom - desc_tr.top);
                height_array[2].push(time_tr.bottom - time_tr.top);
            }
        
            for(var delt = 0; delt < this.$store.state.logistic.static_table_data.length; delt++) {
                var height = this.getMax([height_array[0][delt], height_array[1][delt], height_array[2][delt]]);
                
                deliveryTable_tr[delt].style.height = height + 'px';
                descTable_tr[delt].style.height = height + 'px';
                timeTable_tr[delt].style.height = height + 'px';
            }
        }
    },
    getMax(array_e) {
        return Math.max(...array_e);
    },
    sortBy(value) {
        this.table_option.sort_by = value
        this.table_option.sort_dir = this.table_option.sort_by === value 
            ? (this.table_option.sort_dir === 'desc' ? 'asc' : 'desc') 
            : 'desc'
        this.$store.dispatch('logistic/getLogistics', this.table_option);
    },
    edit(srn) {
        const time_re = new RegExp('(.*?)(?<=_)time');
        const date_re = new RegExp('(.*?)(?<=_)date');
      const obj = Object.values(this.$store.state.logistic.logistic_data.data)
      const found_obj = obj.find((key) => key.srn === srn)
      var data = {}
      for(var key in found_obj.inner_data) {
        Object.assign(data, found_obj.inner_data[key])

        for(var item in found_obj.inner_data[key]) {
            if(date_re.test(item) && found_obj.inner_data[key][item]) {
                var date_split = found_obj.inner_data[key][item].split(' ')

                const date = new Date(`${date_split[0].replace('.', '')} ${date_split[1]}, ${date_split[2]} 00:00:00`)
                data[item] = date
            }
            if(time_re.test(item) && found_obj.inner_data[key][item]) {
                var time_split = found_obj.inner_data[key][item].split(' ')
                var additional_time = 0
                if(time_split[1] === 'pm' || time_split[1] === 'PM') {
                    additional_time = 12
                }
                else {
                    additional_time = 0
                }
                time_split = time_split[0].split(':')
                const time_obj = { 'hours': parseInt(time_split[0]) + parseInt(additional_time), 'minutes': time_split[1] }

                data[item] = time_obj
            }
        }
      }
        Object.assign(data, {
            id: found_obj.id,
            srn: found_obj.srn,
            items: found_obj.items,
            last_edited: found_obj.last_edited,
            remind_at: found_obj.remind_at
        })
      this.$store.commit({
        type: 'logistic/SET_SINGLE_LOGISTIC_DATA',
        data: data
      })
      this.$router.push({ path: '/update-status', query: { srn: srn } })
    },
    closeModal() {
        document.getElementById('modal-container').style.display = "none";
    },
    async deleteModal(id) {
        document.getElementById('modal-container').style.display = "block";

        const obj = Object.values(this.$store.state.logistic.logistic_data.data)

        const found_obj = obj.find((key) => key.id === id)

        Object.assign(this.to_deleteLogistic, found_obj)
    },
    async del(id) {
      await this.$store.dispatch('logistic/deleteLogistic', id).then(() => {
        this.deleteMsg = this.$store.state.logistic.message
        this.closeModal()
          
        this.$store.dispatch('logistic/getLogistics', this.table_option);
        
        document.getElementById('logistic_table').scrollIntoView()
      })
    },
  },
  computed: {
    search: {
      get() {
        return this.table_option.search ?  this.table_option.search : this.$store.state.tablecontroller.search;
      },
      set(value) {
        this.table_option.search = value
        this.table_option.currentPage = 1
        this.$store.dispatch('logistic/getLogistics', this.table_option);
      },
    },
    sort_by: {
      get() {
        return this.table_option.sort_by ?  this.table_option.sort_by : this.$store.state.tablecontroller.sort_by;
      }
    },
    sort_direction: {
      get() {
        return this.table_option.sort_dir ?  this.table_option.sort_dir : this.$store.state.tablecontroller.sort_dir;
      },
      set(value) {
        this.table_option.sort_dir = value
        this.$store.dispatch('logistic/getLogistics', this.table_option);
      },
    },
    perPage: {
      get() {
        return this.table_option.perPage ?  parseInt(this.table_option.perPage) : this.$store.state.tablecontroller.perPage;
      },
      set(value) {
        this.table_option.perPage = value
        this.$store.dispatch('logistic/getLogistics', this.table_option);
      }
    },
    totalPages: {
        get() {
            return this.$store.state.logistic.totalPages
        }
    },
    currentPage: {
        get() {
            return this.$store.state.logistic.currentPage
        }
    },
    isLoading: {
        get() {
            return this.$store.state.logistic.isLoading
        }
    },
  },
}
</script>